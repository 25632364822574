<template>
  <div class="order-payment">
    <order-info
      hand-flag="zf"
      :dishInfo="dishPacList"
      :deliveryInfo="deliveryForm"
      :deliveryTime.sync="deliveryForm.deliveryTime"
      :houseAddress.sync="deliveryForm.houseAddress"
      :noHouseAddress="noHouseAddress"
    />
    <div class="footer-box">
      <div class="dining-car">
        <div class="din-car-img">
          <!-- <img class="img" src="">
          <div class="din-car-number">{{ dishPacList.length }}</div> -->
        </div>
        <div class="price-amount-total">
          <div class="price-text">¥ {{ totalPrice }}</div>
          <div class="amount-text">
            共 <span class="num-text">{{ totalQuantity }}</span> 份
          </div>
        </div>
      </div>
      <div class="now-down-order-btn" @click="applyDownOrderOnClick">确认下单</div>
    </div>
  </div>
</template>

<script>
import orderInfo from './components/orderInfo.vue';
import moment from 'moment';
import { mapState } from 'vuex';
import { userInfoURL, savePlaceOrderInfoUrl, getHouseListUrl } from './api.js';
import Big from 'big.js';

moment.locale('zh-cn');

export default {
  name: 'eldCanOrderPayment',
  components: {
    orderInfo
  },
  data() {
    return {
      dishPacList: [],
      deliveryForm: {
        tenantId: '',
        userId: '',
        userName: '',
        mobileNum: '',
        spaceId: '',
        spacePath: '',
        houseAddress: '',
        orderDishes: '',
        orderAmount: void 0,
        deliveryTime: null,
        supplyTimeStart: null,
        supplyTimeEnd: null
      },
      noHouseAddress: false
    };
  },
  computed: {
    ...mapState(['userId', 'tenantId', 'communityId', 'houseId']),
    totalQuantity() {
      return this.dishPacList.map(item => +item.quantity).reduce((prev, next) => Big(prev).plus(next).toNumber(), 0);
    },
    totalPrice() {
      return this.dishPacList
        .map(item => Big(item.quantity).times(item.price).toNumber())
        .reduce((prev, next) => Big(prev).plus(next).toNumber(), 0);
    }
  },
  watch: {},
  created() {
    // this.$vnode.parent.componentInstance.cache
    // this.$axios
    //   .post(getHouseListUrl, { userId: this.userId, tenantId: this.tenantId })
    //   .then(res => {})
    //   .catch(() => {});
    const dishPacListText = sessionStorage.getItem('jingting_dining_car_list');
    if (dishPacListText && dishPacListText !== 'undefined') {
      this.dishPacList = JSON.parse(dishPacListText).filter(item => !item.ygdcsdText);
      this.deliveryForm.orderDishes = this.dishPacList
        .map(item => (['', void 0, null, NaN].includes(item.dishId) ? item.packageName + '×' + item.quantity : item.dishName + '×' + item.quantity))
        .join('、');
      this.noHouseAddress = this.dishPacList.map(item => item.typeName).includes('午餐');
    }
    const dcsj = sessionStorage.getItem('jingting_dining_car_time');
    this.deliveryForm.deliveryTime = dcsj;
    const arr = this.dishPacList
      .map(item => [item.supplyTimeStart.slice(11), item.supplyTimeEnd.slice(11)])
      .filter(item => item[0] && item[1])
      .sort((a, b) => new Date(dcsj + ' ' + a[0]).getTime() - new Date(dcsj + ' ' + b[0]).getTime());
    if (arr[0]) {
      this.deliveryForm.supplyTimeStart = arr[0][0];
      this.deliveryForm.supplyTimeEnd = arr[0][1];
    }
    this.$axios
      .get(userInfoURL, { params: { userId: this.userId } })
      .then(res => {
        if (res && res.code === 200) {
          this.deliveryForm.userId = res.data.userId;
          this.deliveryForm.userName = res.data.userName;
          this.deliveryForm.mobileNum = res.data.mobile;
          this.deliveryForm.tenantId = this.tenantId;
          this.deliveryForm.spaceId = res.data.chosenSpaceId;
          this.deliveryForm.spacePath = res.data.chosenSpacePath;
        }
      })
      .catch(() => {});
  },
  methods: {
    applyDownOrderOnClick() {
      const requiredKeys = ['userName', 'mobileNum', 'deliveryTime'];
      this.noHouseAddress === false && requiredKeys.push('houseAddress');
      if (requiredKeys.some(k => ['', void 0, null, NaN].includes(this.deliveryForm[k]))) {
        this.$toast('请将订单信息填写完整');
        return;
      }
      if (this.dishPacList.length === 0) {
        this.$toast('请添加餐品后下单');
        return;
      }
      if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.deliveryForm.mobileNum)) {
        this.$toast('请输入正确的联系电话');
        return;
      }
      this.deliveryForm.orderAmount = this.totalPrice;
      const form = this.$_.cloneDeep(this.deliveryForm);
      form.orderDetailList = this.dishPacList.map(item => ({
        dishTypeId: item.dishTypeId,
        saleDetailId: item.id,
        dishId: item.dishId,
        dishName: item.dishName,
        packageId: item.packageId,
        packageName: item.packageName,
        price: item.price,
        quantity: item.quantity
      }));
      this.$axios
        .post(savePlaceOrderInfoUrl, form)
        .then(res => {
          if (res && res.code === 200) {
            this.$store.commit('addNoKeepAlive', 'eldCanPlaceOrder');
            this.$toast(res.msg);
            this.$dialog
              .alert({
                title: '提示',
                message: '下单成功！社区将在约定时间积极为您备餐',
                confirmButtonColor: '#FDBF3A'
              })
              .then(() => {
                this.$router.replace({
                  name: 'eldCanOrderDetail',
                  query: { id: res.data }
                });
              });
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
.order-payment {
  box-sizing: border-box;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  padding-bottom: 158px;
  overflow-x: hidden;
  overflow-y: auto;
}
.order-info {
  padding: 20px 30px;
}
.footer-box {
  box-sizing: border-box;
  border-top: 1px solid #f1f1f1;
  width: 100%;
  height: 158px;
  height: calc(158px + constant(safe-area-inset-bottom));
  height: calc(158px + env(safe-area-inset-bottom));
  position: fixed;
  left: 0px;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 44px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 99;
  background-color: #ffffff;
  .dining-car {
    box-sizing: border-box;
    padding-right: 20px;
    display: flex;
    align-items: center;
    .din-car-img {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 88px;
      height: 104px;
      margin-right: 20px;
      position: relative;
      background: url('./img/meal_bag.png') no-repeat 50% 50%;
      background-size: cover;
      .img {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      .din-car-number {
        box-sizing: border-box;
        position: absolute;
        right: -10px;
        top: -10px;
        min-width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: #fe3c00;
        color: #ffffff;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
      }
    }
    .price-amount-total {
      flex-shrink: 0;
      .price-text {
        font-size: 40px;
        line-height: 44px;
        color: #e02020;
        font-weight: 700;
      }
      .amount-text {
        font-size: 24px;
        color: #818181;
        .num-text {
          font-size: 36px;
          color: #f80000;
          font-weight: 700;
        }
      }
    }
  }
  .now-down-order-btn {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 256px;
    height: 96px;
    border-radius: 48px;
    color: #ffffff;
    font-size: 32px;
    line-height: 36px;
    background: linear-gradient(180deg, #fdc870 0%, #faac3b 100%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
